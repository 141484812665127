/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

var px_per_m = 15;

(function($) {


  function is_mobile () {

    if ($('html').hasClass('touchevents') && $(window).height() <= 1366 && $(window).width() < $(window).height()) {
      return true;
    }

    return false;
  }


  /**
   * Check if section has a scroll class, if it does then we handle it in SM differently.
   * @param $this
   * @returns {boolean}
   */
  function has_scroll_class ($this) {
    var classes = $this.attr('class').split(/\s+/);
    for (var i=0; i<classes.length; i++) {
      if (classes[i] === 'scroll-images') {
        return true;
      }
    }

    return false;
  }//end has_scroll_class


  function image_scroll_progress_change (progress, direction, scroll_progress) {

    if (direction === 'FORWARD') {
      if (progress > scroll_progress.interval_check) {

        //Don't hide the last image for the scroll class
        if (scroll_progress.is_scroll_class) {
          if (scroll_progress.img_index < (scroll_progress.bg_imgs.length - 1)) {
            $(scroll_progress.bg_imgs[scroll_progress.img_index]).addClass('hide');
            scroll_progress.img_index++;
            scroll_progress.interval_check += scroll_progress.interval;
          }
        } else {
          $(scroll_progress.bg_imgs[scroll_progress.img_index]).addClass('hide');
          scroll_progress.img_index++;
          scroll_progress.interval_check += scroll_progress.interval;
        }

      }
    } else {
      if (progress < scroll_progress.interval_check) {
        $(scroll_progress.bg_imgs[scroll_progress.img_index]).removeClass('hide');

        scroll_progress.img_index--;
        scroll_progress.interval_check -= scroll_progress.interval;
      }
    }

    return scroll_progress;

  }//end image_scroll_progress_change


  function load_depth_meter () {
    var depth_meter = $('.depth-meter'),
      markers = $('span.markers'),
      rows = $('div.items-row'),
      marker_inc = 500,
      max_depth = parseInt(rows.last().data('row-depth')),
      inc = parseInt(depth_meter.height()) / (max_depth / marker_inc),
      marker_top = 0,
      html = '<span class="marker" style="top: ' + marker_top + ';">0</span>';

    for (var i = marker_inc; i < max_depth; i += marker_inc) {
      marker_top += inc;
      html += '<span class="marker" style="top: ' + marker_top + 'px;">' + i + '</span>';
    }

    html += '<span class="marker" style="top: ' + depth_meter.height() + 'px;">' + max_depth + '</span>';
    markers.html(html);
  }


  function stick_depth_meter () {
    if ($('.depth-meter').length) {
      var meter = $('.depth-meter');
      var offset = ((parseInt($(window).outerHeight()) + parseInt($('header').height())) - parseInt(meter.height())) / 2;
      $('.depth-meter').stick_in_parent({
        offset_top: offset
      });
    }
  }


  function stick_water_gradient () {
    if ($('.gradient-overlay').length) {
      $('.gradient-overlay').stick_in_parent();
    }
  }


  function setup_page () {
    var rows = $('div.items-row');
    var max_depth = parseInt(rows.last().data('row-depth'));
    var page = $('#ds-page');
    var footer_svg = parseInt($('div.footer-bg-svg').height());

    page.height((max_depth * px_per_m) + footer_svg);

    //Layout the items:
    rows.each(function(){
      var $this = $(this);
      var prev = $this.prev();
      var item_top = 0;
      var item_h =  parseInt($this.data('row-depth')) * px_per_m;
      if (prev.length) {
        item_top = parseInt(prev.data('row-depth')) * px_per_m;
        item_h =  (parseInt($this.data('row-depth')) - parseInt(prev.data('row-depth'))) * px_per_m;
      }
      $this.css('top', item_top+'px');
      $this.height(item_h);
    });


    //Setup the zones:
    var zone_data = $('#ds-page').data('zones');
    var gradient_container = $('div.gradient-container');
    var zone_names = $('div.zone-names');
    var keys = Object.keys(zone_data);
    var gradient_style = '';

    for (var i=0; i<keys.length; i++) {
      var grad_perc = ((zone_data[keys[i]].zone_depth * px_per_m) / page.height()) * 100;
      var grad_color = zone_data[keys[i]].zone_color;

      gradient_style += grad_color+' '+grad_perc+'%, ';


      var text = zone_data[keys[i]].zone_name;

      zone_names.append('<p id="zone-name-'+zone_data[keys[i]].zone_depth+'" class="zone-name" style="position: absolute; width: 100%; text-align: center; top: '+grad_perc+'%; z-index: 100;"><span class="sr-only">'+text+'</span></p>');
    }

    gradient_style = gradient_style.slice(0, -2);
    gradient_container.css('background', 'linear-gradient(180deg, '+gradient_style+')');


    stick_water_gradient();


    //Setup the notes:
    var notes = $('p.note');
    if (notes.length) {
      notes.each(function(){
        var depth = $(this).data('depth');
        var pos_top = parseInt(depth) * px_per_m;
        $(this).css('top', pos_top+'px');
      });
    }




  }


  function setup_blotter () {
    var zone_names = $('div.zone-names p.zone-name');
    var material = new Blotter.LiquidDistortMaterial();

    material.uniforms.uSpeed.value = 0.3;
    material.uniforms.uVolatility.value = 0.01;
    material.uniforms.uSeed.value = 0.4;

    zone_names.each(function () {

      var $this = $(this);
      var container = document.getElementById($this.attr('id'));

      const text = new Blotter.Text($this.find('span').text(), {
        family: "Crimson Text,Times New Roman,serif",
        size: 45,
        weight: 'bold',
        fill: "#ffffff",
      });


      var blotter = new Blotter(material, {
        texts: text
      });

      var scope = blotter.forText(text);

      scope.appendTo(container);

    });
  }


  function sticky_filter_bar() {
    if ($('#resources-filter').length) {
      var os = $('header').outerHeight();
      $('#resources-filter').stick_in_parent({
        offset_top: os
      });
    }
  }



  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        //Mobile nav menu click
        $(document).on('click', 'div.hamburger', function () {
          $(this).toggleClass('is-active');
          $('header nav').toggleClass('active');
        });


        //Close the mobile menu on nav item click
        $(document).on('click', 'ul#page-nav li a', function () {
          if ($('div.hamburger').hasClass('is-active')) {
            $('div.hamburger').trigger('click');
          }
        });

        //Anchor no action click
        $('a.anchor-no-action').click(function(e){

          e.preventDefault();
          return false;

        });



        //Sticky filter bar
        sticky_filter_bar();
        $(window).resize(function () {
          sticky_filter_bar();
        });
        //Sticky filter bar


        //Isotope actions
        //Assets filter Isotope
        $(window).load(function () {

          if ($('body').hasClass('mid-blog-landing') && $('.isotope-wrapper').length) {
            var $container = $('.isotope-wrapper');
            $container.isotope({
              itemSelector: '.isotope-item',
              layoutMode: 'fitRows',
              getSortData: {
                post_date: '.asset-ts'
              }
            });
          }

        });


        //Tags select onChange
        $('select#assets-tags-select').on('change', function () {

          if ($('.isotope-wrapper').length) {

            var val = $(this).val();
            if (val !== '*') {
              val = '.' + val;
            }

            $('.isotope-wrapper').isotope({filter: val});
            if (!$('.isotope-wrapper').data('isotope').filteredItems.length) {
              $('div.isotope-no-results').addClass('active');
            } else {
              $('div.isotope-no-results').removeClass('active');
            }
          }
        });


        //Assets order onChange
        if ($('select#assets-order-select').length) {
          $('select#assets-order-select').change(function(){
            var order = ($(this).val() === 'asc') ? 'post_date' : 'original-order';
            $('.isotope-wrapper').isotope({sortBy: order});
          });
        }

        //Isotope actions


        $('.posts-carousel').owlCarousel({
          items: 3,
          dots: false,
          nav: true,
          navText: ['<i class="ion-chevron-left"></i>', '<i class="ion-chevron-right"></i>'],
          responsiveClass:true,
          margin: 15,
          responsive: {
            0: {
              items: 1,
              margin: 0,
              nav: false,
              dots: true
            },
            768: {
              items: 2,
              margin: 0
            },
            1024: {
              items: 3,
              margin: 0
            },
            1025: {
              items: 3,
              margin: 15
            },
            1600: {
              items: 3,
              margin: 15
            }
          },
          onInitialized: function(){
            var img_h = $('div.posts-carousel .topic-item img').height();
            $('div.posts-carousel div.owl-nav button').height(img_h);
          },
          onResize: function(){
            setTimeout(function () {
              var img_h = $('div.posts-carousel .topic-item img').height();
              $('div.posts-carousel div.owl-nav button').height(img_h);
            }, 350);
          }
        });//end img carousel


        //select 2 init
        if ($('.styled-select').length) {
          $('.styled-select').select2({
            minimumResultsForSearch: Infinity
          });
        }
























        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'page_template_front_page': {
      init: function() {

        AOS.init();

        $("a.page-scroll").bind("click", function (e) {
          var offset = $('header').height(),
            t = $(this),
            anchor = t.attr("href");

          if (anchor === '#next-section') {
            var p = t.closest('section');
            if (typeof p !== 'undefined') {
              anchor = p.next('section');
            }
          }

          $("html, body").stop().animate({
            scrollTop: (parseInt($(anchor).offset().top) - offset)
          }, 1500, "easeInOutExpo");
          e.preventDefault();
        });


        //OnScroll
        $(window).on('scroll', function () {

          //Set the active nav
          $('section').each(function () {

            if ($(this).attr('id') !== '' && $(this).attr('id') !== undefined) {
              var offset = $('header').height();
              var ost = (parseInt($(this).offset().top) - offset);
              var st = $(window).scrollTop();
              var id = $(this).attr('id');

              if ((st >= ost)) {

                //remove active class from all others
                $('ul#page-nav li').removeClass('active');

                //And add to this one.
                $('ul#page-nav li a[href="#' + id + '"]').parent('li').addClass('active');

              } else if (st < $(window).innerHeight()) {
                $('ul#page-nav li').removeClass('active');
              }
            }

          });

        });//end on Scroll


        //Set the introduction id
        $('section.hero_section + section').attr('id', 'introduction');

        // Init ScrollMagic
        var controller = new ScrollMagic.Controller();
        var sections = $('section.image, section.video').not('.hero_section');

        sections.each(function(){

          var $this = $(this),
              bg = $this.find('div.background'),
              content = $this.find('.content-outer');

          //Pinning
          if (content.outerHeight() > bg.height()) {

            var sm_scene = new ScrollMagic.Scene({
              triggerElement: this,
              triggerHook: 0,
              duration: content.outerHeight()
            })
              .setPin(bg[0])
              .addTo(controller);

          }//end if attach SM


          //If single img do parallax:
          if ($this.hasClass('single-img') || $this.hasClass('background_section') || $this.hasClass('heading_section')) {

            var $bg = $this.find('div.background');

            var slideParallaxScene = new ScrollMagic.Scene({
              triggerElement: this,
              triggerHook: 1,
              duration: "100%"
            })
              .setTween(TweenMax.from($bg, 1, {y: '-40%', autoAlpha: 0.3, ease: Power0.easeNone}))
              .addTo(controller);

          }//single img parallax


          //If has a scroll class then pin it and set the duration based on the number of images -1 (to reduce scroll time).
          if (has_scroll_class($this) && !is_mobile()) {

            var imgs = (is_mobile()) ? $this.find('div.bg-img-wrap.mobile div.bg-img-inner') : $this.find('div.bg-img-wrap.desktop div.bg-img-inner');

            var scroll_progress = {
              bg_imgs: imgs,
              img_index: 0,
              interval: null,
              interval_check: null,
              is_scroll_class: true
            };

            scroll_progress.interval = (1 / scroll_progress.bg_imgs.length);
            scroll_progress.interval_check = scroll_progress.interval;

            var scroll_section = $this.find('.scroll-section-inner'),
                duration = ((scroll_progress.bg_imgs.length / 4) * 100)+'%';

            var sm_scene = new ScrollMagic.Scene({
              triggerElement: this,
              triggerHook: 0,
              offset: (is_mobile()) ? -$('header').outerHeight() : 0,
              duration: duration
            })
              .on("progress", function (e) {
                var progress = e.progress.toFixed(3),
                    direction = e.target.controller().info("scrollDirection");

                scroll_progress = image_scroll_progress_change(progress, direction, scroll_progress);
              })
              .setPin(scroll_section[0])
              .addTo(controller);
          }//end has_scroll_class


          if ($this.hasClass('multi-img') && !has_scroll_class($this)) {

            var duration = (content.outerHeight() - $(window).innerHeight());
            var scroll_progress = {
              bg_imgs: $this.find('div.bg-img-inner'),
              img_index: 0,
              interval: null,
              interval_check: null
            };

            scroll_progress.interval = (1 / scroll_progress.bg_imgs.length);
            scroll_progress.interval_check = scroll_progress.interval;

            var scene = new ScrollMagic.Scene({
              triggerElement: this,
              triggerHook: 0,
              duration: duration
            })
              .on("progress", function (e) {
                var progress = e.progress.toFixed(3),
                    direction = e.target.controller().info("scrollDirection");

                scroll_progress = image_scroll_progress_change(progress, direction, scroll_progress);
              })
              .addTo(controller)

          }//end if has multi img


        });//end each sections





        //Video popups
        $('.video-popup').magnificPopup({
          type: 'iframe',
          preloader: true,
          removalDelay: 500, //delay removal by X to allow out-animation
          callbacks: {
            beforeOpen: function () {
              // just a hack that adds mfp-anim class to markup
              this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
              this.st.mainClass = this.st.el.attr('data-effect');
            }
          },
          closeOnContentClick: true
        });//end video modal


        $('a.mid-popup').magnificPopup({
          type: 'image',
          closeOnContentClick: true,
          mainClass: 'mfp-img-mobile mfp-with-zoom',
          image: {
            verticalFit: true
          },
          zoom: {
            enabled: true,
            duration: 300 // don't foget to change the duration also in CSS
          }

        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    //Deep sea page
    'the_deep_sea': {
      init: function() {

        setup_page();
        setup_blotter();


        //Tooltip / image hover:
        $(".mid-tooltip").mouseover(function () {
          $('div.item img').not($(this).find('img')).addClass('img-fade');
        });

        $(".mid-tooltip").mouseout(function () {
          $('div.item img').removeClass('img-fade');
        });


        particlesJS.load('particles-js', '/wp-content/themes/MineralsInDepth/vendor/js/particlesjs-config.json');


        imagesLoaded('div.item img', function () {

          load_depth_meter();
          stick_depth_meter();


          var controller = new ScrollMagic.Controller();
          $('.items-row').each(function(){
            var this_id = $(this).attr('id');

            var tween = new TimelineMax()
              .from('#'+this_id, 1.5, {opacity: 0.15})
              .to('#'+this_id, 1.5, {opacity: 0.15});

            // build scene
            new ScrollMagic.Scene({
              triggerElement: '#trigger-'+this_id,
              duration: (parseInt($(this).height()) * 2), // use full viewport
            })
              .setTween(tween)
              //.addIndicators({name: "GSAP - "+this_id}) // add indicators (requires plugin)
              .addTo(controller);
          });






          var marker_percentage = 0;
          var page_h = parseInt($('#ds-page').height());
          var max_depth = parseInt($('.items-row').last().data('row-depth'));
          $(window).scroll(function () {
            var dsp_st = parseInt($(this).scrollTop());

            marker_percentage = (dsp_st / page_h) * 100;

              if (marker_percentage > 100) {
                marker_percentage = 100;
              }

              $('.current-marker').css('top', marker_percentage + "%");
              $('span.current-depth span.depth').text( ((marker_percentage * max_depth) / 100).toFixed(0));

          }).trigger('scroll');
        });

      }//end deep sea init function
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
